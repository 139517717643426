/* eslint-disable import/no-unresolved, import/extensions */
import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import OriginalForm from 'react-jsonschema-form';
import SchemaField from 'react-jsonschema-form/lib/components/fields/SchemaField';
import Markdown from 'markdown-to-jsx';
import BaseWidgets from './components/widgets';
import BaseFields from './components/fields';
import BaseFieldTemplate from './components/FieldTemplate';
import { getPages, isFormPaginated, isFormChatUI } from './logic';


const CustomSchemaField = function (props) {
  const propsToRender = { ...props };
  const newUiSchema = { ...(props.uiSchema || {}) };

  if (!newUiSchema.classNames) {
    newUiSchema.classNames = '';
  }
  if (props.name) {
    newUiSchema.classNames += ` field-id-${props.name}`;
  }

  if (
    props.uiSchema &&
    props.uiSchema['ui:description'] &&
    props.uiSchema['ui:enableMarkdownInDescription'] &&
    typeof props.uiSchema['ui:description'] === 'string'
  ) {
    newUiSchema['ui:description'] = (
      <Markdown options={{ disableParsingRawHTML: true }}>
        {props.uiSchema['ui:description']}
      </Markdown>
    );
  }

  return <SchemaField {...{ ...propsToRender, uiSchema: newUiSchema }} />;
};

const sectionFields = {
  SchemaField: CustomSchemaField,
};

const allFields = { ...BaseFields, ...sectionFields };
const allWidgets = { ...BaseWidgets };

const Form = ({ fields, widgets, FieldTemplate, formRef, ...props }) => {
  return (
    <OriginalForm
      {...props}
      ref={formRef}
      fields={allFields}
      widgets={allWidgets}
      FieldTemplate={FieldTemplate || BaseFieldTemplate}
      className="ant-form ant-form-horizontal"
    />
  );
};

Form.propTypes = {
  fields: PropTypes.object,
  widgets: PropTypes.object,
  FieldTemplate: PropTypes.func,
};

Form.defaultProps = {
  fields: {},
  widgets: {},
  FieldTemplate: null,
};

export { getPages, isFormPaginated, isFormChatUI };

export default Form;
